import React from 'react';
import PropTypes from 'prop-types';
import pick from 'ramda/src/pick';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { scroller } from 'react-scroll';
import {
  getSurvey,
  addQuestion,
  moveQuestion,
  changeSurveyProperty,
  changeQuestionProperty,
  changeName,
  deleteQuestion,
  cloneQuestion,
  saveSurvey,
  addQuestionTag,
  removeQuestionTag,
  updateQuestion,
} from 'src/actionCreators/surveyActionCreators';
import { confirm } from 'src/components/ConfirmModal/ConfirmModal';
import { Section } from 'src/components/IMUI';
import { ProgressBar } from 'src/components/IMUI/ProgressBar/ProgressBar';
import TranslateButton from 'src/components/TranslateButton/TranslateButton';
import Warning from 'src/components/Warning/Warning';
import { getSurveyName, getI18nCompleteness } from 'src/utils/surveysI18n';
import { Icon } from 'im/ui/Icon';
import RenameSurveyModal from '../components/RenameSurveyModal';
import SurveyEditFooter from '../components/SurveyEditFooter';
import QuestionsList from './QuestionsList';
import cls from './Builder.module.css';
import { canBuildSurvey } from 'src/userStorage';
import Waiting from 'src/pages/App/Analysis/TagEditor/components/Waiting';

@connect(pick(['survey']), {
  getSurvey,
  addQuestion,
  moveQuestion,
  changeQuestionProperty,
  changeName,
  deleteQuestion,
  saveSurvey,
  cloneQuestion,
  addQuestionTag,
  removeQuestionTag,
  changeSurveyProperty,
  updateQuestion,
})
export default class SurveyBuilder extends React.PureComponent {
  static propTypes = {
    ...ReactRouterPropTypes,
    survey: PropTypes.object.isRequired,
    tagsWithTagGroups: PropTypes.array.isRequired,
    addQuestion: PropTypes.func.isRequired,
    moveQuestion: PropTypes.func.isRequired,
    changeQuestionProperty: PropTypes.func.isRequired,
    updateQuestion: PropTypes.func.isRequired,
    changeName: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    cloneQuestion: PropTypes.func.isRequired,
    saveSurvey: PropTypes.func.isRequired,
    addQuestionTag: PropTypes.func.isRequired,
    removeQuestionTag: PropTypes.func.isRequired,
    getSurvey: PropTypes.func.isRequired,
    activeStepId: PropTypes.number,
  };

  state = { showRenameSurveyModal: false, selectedQuestionId: null };

  // Set not fully translated language when going back from translations warning in Share step
  componentDidMount() {
    if (
      this.props.location.state?.activeLanguage &&
      this.props.location.state.activeLanguage !== this.props.survey.language
    ) {
      this.handleLanguageChanged({
        value: this.props.location.state.activeLanguage,
      });
    }
  }
  // Restore server data when changes not saved and route being changed
  componentDidUpdate(prevProps) {
    if (this.props.survey.id && !prevProps.survey.id) {
      this.props.history.replace({
        pathname: `/analysis/${this.props.match.params.projectId}/surveys/${
          this.props.survey.id || this.props.match.params.surveyId
        }/`,
      });
    }

    if (prevProps.survey.activeLanguage !== this.props.survey.activeLanguage) {
      this.props.changeSurveyProperty(
        'activeLanguage',
        this.props.survey.activeLanguage
      );
    }

    if (
      !prevProps.survey.pending &&
      prevProps.survey.questions &&
      this.props.survey.questions &&
      prevProps.survey.questions.length < this.props.survey.questions.length
    ) {
      const previousId = (prevProps.survey.questions || []).map((q) => q?.id);
      const newQuestion = (this.props.survey.questions || []).find(
        (q) => !previousId.includes(q.id)
      );
      if (newQuestion) this.handleQuestionSelect(newQuestion);
    }
  }
  componentWillUnmount() {
    if (!this.props.survey.dirty) return;
    this.props.getSurvey(
      this.props.match.params.surveyId,
      this.props.match.params.projectId,
      { silent: true }
    );
  }
  handleSaveSurvey = () => {
    if (!canBuildSurvey()) return;
    if (this.state.selectedQuestionId) {
      this.showEditingWarningDialog();
    } else {
      this.props.saveSurvey(
        this.props.survey,
        this.props.match.params.projectId
      );
    }
  };
  handleQuestionAdd = (type, isLayout, position) => {
    if (!canBuildSurvey()) return;
    const questionPosition =
      typeof position === 'number'
        ? position
        : this.props.survey.questions.length;
    this.props.addQuestion(type, isLayout, questionPosition);
  };
  handleQuestionSelect = (question = {}) => {
    this.setState({ selectedQuestionId: question?.id || null });
  };
  handleQuestionDeselect = () => {
    if (!canBuildSurvey()) return;
    this.handleQuestionSelect();
  };
  handleQuestionClone = (question, position) => {
    if (!canBuildSurvey()) return;
    this.handleQuestionDeselect();
    this.props.cloneQuestion(question, position);
  };
  handleQuestionDelete = (question) => {
    if (!canBuildSurvey()) return;
    this.handleQuestionDeselect();
    this.props.deleteQuestion(question.id);
  };
  handleQuestionTagAdd = (question, tagId) => {
    if (!canBuildSurvey()) return;
    this.props.addQuestionTag(question.id, tagId);
  };
  handleQuestionTagRemove = (question, tagId) => {
    if (!canBuildSurvey()) return;
    this.props.removeQuestionTag(question.id, tagId);
  };
  handleRenameClick = () => {
    if (!canBuildSurvey()) return;
    this.setState({ showRenameSurveyModal: true });
  };
  handleRenameSurveyModalConfirm = (name) => {
    this.setState({ showRenameSurveyModal: false });
    this.handleUpdateI18nName(name);
  };
  handleRenameSurveyModalClose = () => {
    this.setState({ showRenameSurveyModal: false });
  };
  handleUpdateI18nName = (values) => {
    if (!canBuildSurvey()) return;
    if (typeof values === 'string') {
      this.props.changeName(values);
    } else {
      this.props.survey.languages.forEach((langCode) => {
        this.props.changeName(values[langCode], langCode);
      });
    }
  };
  handleLanguageChanged = ({ value }) => {
    if (!canBuildSurvey()) return;
    this.props.changeSurveyProperty('activeLanguage', value, { silent: true });
  };
  scrollToQuestion = (questionId, target) => {
    const questionExists = questionId
      ? this.props.survey.questions.some((q) => q.id == questionId)
      : false;
    if (!questionExists && !target) return;
    setTimeout(() => {
      scroller.scrollTo(target || `question-${questionId}`, {
        duration: 100,
        delay: 0,
        smooth: true,
        offset: target ? -300 : -200,
        container: this.innerRef?.parentElement,
      });
    }, 5);
  };
  showEditingWarningDialog() {
    this.scrollToQuestion(this.state.selectedQuestionId);
    confirm({
      title: 'Unsaved changes',
      text: `Before saving the survey please finish editing your active question and either accept changes by pressing Done or discard this question if you wish to by clicking Cancel or Delete.`,
      confirmLabel: 'Ok',
      hideCancel: true,
    }).then(() => void 0);
  }

  render() {
    const surveyName = getSurveyName(this.props.survey);
    const completeness = getI18nCompleteness(this.props.survey);

    const translationProgress = Math.round(
      (this.props.survey.languages.reduce(
        (acc, langCode) => acc + completeness[langCode].completenessRatio,
        0
      ) /
        this.props.survey.languages.length) *
        100
    );

    if (this.props.survey.status === 'pending') {
      return <Waiting />;
    }

    return (
      <div ref={(ref) => (this.innerRef = ref)}>
        <Section surveyWidth collapsed className={cls.surveyBuilder}>
          <Warning
            compact
            id={this.props.survey.id}
            className={cls.surveyBuilderWarning}
            type="surveyTranslations"
            show={!!completeness.errors && !this.state.selectedQuestionId}
            text="Your survey is not fully translated."
          />

          <div className={cls.surveyName} onClick={this.handleRenameClick}>
            {surveyName || 'New survey'}
            {canBuildSurvey() && (
              <Icon name="edit" tip="Edit" onClick={this.handleRenameClick} />
            )}
            <TranslateButton
              error={!!completeness.survey.name.length}
              owner={this.props.survey}
              languages={this.props.survey.languages}
              values={this.props.survey.i18n}
              path="name"
              altPath="name"
              title="Translate survey title"
              onChange={this.handleUpdateI18nName}
            />
          </div>
          {this.props.survey.languages.length >= 1 &&
            translationProgress !== 100 && (
              <ProgressBar
                progress={translationProgress}
                tip={`Translation in progress - ${translationProgress}%`}
              />
            )}
          <QuestionsList
            survey={this.props.survey}
            projectId={this.props.match.params.projectId}
            tagsWithTagGroups={this.props.tagsWithTagGroups}
            completeness={completeness}
            selectedQuestionId={this.state.selectedQuestionId}
            onScrollToQuestion={this.scrollToQuestion}
            onQuestionAdd={this.handleQuestionAdd}
            onQuestionMove={this.props.moveQuestion}
            onQuestionClick={this.handleQuestionSelect}
            onQuestionDeselect={this.handleQuestionDeselect}
            changeQuestionProperty={this.props.changeQuestionProperty}
            updateQuestion={this.props.updateQuestion}
            onQuestionDelete={this.handleQuestionDelete}
            onQuestionClone={this.handleQuestionClone}
            onQuestionTagAdd={this.handleQuestionTagAdd}
            onQuestionTagRemove={this.handleQuestionTagRemove}
            onSaveSurvey={this.handleSaveSurvey}
          />
        </Section>
        <SurveyEditFooter
          survey={this.props.survey}
          completeness={completeness}
          onJumpToQuestion={this.scrollToQuestion}
          onLanguageChanged={this.handleLanguageChanged}
          onSaveSurvey={this.handleSaveSurvey}
          onRenameSurvey={this.handleUpdateI18nName}
          translationProgress={translationProgress}
          projectId={this.props.match.params.projectId}
          hasAutoTranslateButton={this.props.activeStepId === 'design'}
        />
        <RenameSurveyModal
          open={this.state.showRenameSurveyModal}
          name={surveyName}
          onClose={this.handleRenameSurveyModalClose}
          onSave={this.handleRenameSurveyModalConfirm}
        />
        <Prompt
          when={this.props.survey.dirty}
          message="You have unsaved changes that will be lost. Are you sure you want to leave?"
        />
      </div>
    );
  }
}
